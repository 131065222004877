import React from 'react'

const SvgFeature4 = props => (
  <svg id='feature4_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature4_svg__st0{fill:#334966}.feature4_svg__st1{fill:#fd5c1f}'}</style>
    <g id='feature4_svg__\u591A\u7EF4\u62A5\u8868\u8FD0\u8425-48x48'>
      <path id='feature4_svg__Rectangle-34' className='feature4_svg__st0' d='M3 6.17v36h44v-36H3zm-2-2h48v40H1v-40z' />
      <circle id='feature4_svg__Oval-16' className='feature4_svg__st1' cx={22.5} cy={25.67} r={9.5} />
      <path
        id='feature4_svg__Combined-Shape_25_'
        d='M22 13.2v11.96H10.04c.48-6.38 5.58-11.47 11.96-11.96z'
        fill='none'
        stroke='#334966'
        strokeWidth={2}
      />
      <path id='feature4_svg__Rectangle-35' className='feature4_svg__st0' d='M37 12.17h4v2h-4z' />
      <path id='feature4_svg__Rectangle-35-Copy' className='feature4_svg__st1' d='M37 19.17h4v2h-4z' />
      <path id='feature4_svg__Rectangle-35-Copy-2' className='feature4_svg__st1' d='M37 26.17h4v2h-4z' />
      <path id='feature4_svg__Rectangle-35-Copy-3' className='feature4_svg__st0' d='M9 34.17h32v2H9z' />
    </g>
  </svg>
)

export default SvgFeature4
