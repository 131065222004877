import React from 'react'

const SvgFeature2 = props => (
  <svg id='feature2_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature2_svg__st1{fill:none;stroke:#334966;stroke-width:2}'}</style>
    <g id='feature2_svg__\u5206\u5E03\u5F0F\u67B6\u6784-48x48'>
      <path id='feature2_svg__Polygon' fill='#fd5c1f' d='M25 11l13.86 8v16L25 43l-13.86-8V19z' />
      <path
        id='feature2_svg__Polygon_1_'
        className='feature2_svg__st1'
        d='M25 3.15l-9.39 5.42v10.85L25 24.85l9.39-5.42V8.58L25 3.15z'
      />
      <path
        id='feature2_svg__Polygon-Copy'
        className='feature2_svg__st1'
        d='M13 25.15l-9.39 5.42v10.85L13 46.85l9.39-5.42V30.58L13 25.15z'
      />
      <path
        id='feature2_svg__Polygon-Copy-2'
        className='feature2_svg__st1'
        d='M37 25.15l-9.39 5.42v10.85L37 46.85l9.39-5.42V30.58L37 25.15z'
      />
    </g>
  </svg>
)

export default SvgFeature2
