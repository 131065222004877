import React from 'react'

const SvgFeature1 = props => (
  <svg id='feature1_svg__tuceng_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.feature1_svg__st0{fill:#2b4b67}.feature1_svg__st1{fill:#fd5c1f}'}</style>
    <path
      className='feature1_svg__st0'
      d='M12.92 18.45v13.09l12 6.55 12-6.55V18.45l-12-6.55-12 6.55zm11 16.96l-9-4.95v-9l9 4.95v9zm11-4.95l-9 4.95v-9l9-4.95v9zM24.63 25l-9.65-5.36 9.65-5.36 9.65 5.36L24.63 25z'
    />
    <path
      className='feature1_svg__st1'
      d='M23.92 35.41l-9-4.95v-9l9 4.95zM34.92 30.46l-9 4.95v-9l9-4.95zM24.63 25l-10.19-5.56 10.19-5.55 10.18 5.55z'
    />
    <path
      className='feature1_svg__st0'
      d='M24.92 49.84l-23-12.4V12.56l23-12.4 23 12.4v24.89l-23 12.39zm-21-13.36l21 11.52 21-11.52V13.52L24.92 2l-21 11.52v22.96z'
    />
  </svg>
)

export default SvgFeature1
